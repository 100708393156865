import React from 'react';
import { Canvas } from "@react-three/fiber";
import Sphere from "../GraphicComponents/Sphere"
import Clouds from '../GraphicComponents/Clouds';
// import * as Three from "@react-three/drei"
// import * as Three2 from "@react-three/fiber"
import * as Three from "three"

import './CanvasPage.css';
import { Effects } from '@react-three/drei';

const CanvasPage = () => {
  console.log(Three)
  return (
    <div className='canvas-page'>
      <Canvas
          camera={{
            fov: 90,
            position: [0, 0, 2],
          }}
          // scene={{
          //   fog: new Three.FogExp2(0x000000, 0.00000025)
          // }}
        >
          <fogExp2 color={0x000000} density={0.00000025} />
          <ambientLight intensity={0.1} />
          <directionalLight color={0xffffff} intensity={0.8} position={[- 1, 0, 1 ]} />
          
          <Sphere />
          <Clouds />
        </Canvas>
    </div>
  );
}

export default CanvasPage;

import React, {useRef, useEffect} from "react";

import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useFrame } from "@react-three/fiber";

import EarthClouds from "../../textures/earth_clouds_2048.png"

const Clouds = () => {

    const cloudRef = useRef<any>(null)
    const textureClouds = useLoader(TextureLoader, EarthClouds)
    useFrame(() => (cloudRef.current.rotation.y += 0.00105));

    return (
        <mesh 
            position={[0, 0, -2]}
            scale={[1.0055, 1.0055, 1.0055]}
            ref={cloudRef}
        >
            <sphereGeometry args={[2, 64, 32]} />
            <meshLambertMaterial 
                map={textureClouds}
                transparent={true}
            />
            
        </mesh>
    )
}

export default Clouds

import React, { useState } from 'react'; 
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';
import { Tag } from 'primereact/tag';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';

import './LeftMenu.css'
import '/node_modules/primereact/resources/themes/saga-blue/theme.css';
import '/node_modules/primereact/resources/primereact.min.css';
// import '/node_modules/primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css';
// import * from '../textures/logo_CZ2_1-600x600.jpg'


const LeftMenu = () => {

    const [showCard, setShowCard] = useState(true)

    // const ffd_ddz = ( <img src='../../images/AB714675-AA50-470B-BEBD-A6CFC1BAFE81.jpeg' ></img> )
    // const digital_earth = ( <img src='../../images/2FEA1E42-28DA-4EFC-A9B5-8B51BB7ECB6E2.jpeg' ></img> )
    // const space_filming = ( <img src='../../images/space_filming.jpg' ></img> )
    // const services = ( <img src='../../images/digital_earth.jpg' ></img> )

    const ffd_ddz_link = (
        <a href='https://next.gptl.ru/'>
            <div className="button-link w-full flex justify-content-center align-items-center font-bold" >
                ПЕРЕЙТИ
            </div>
        </a>
    )
    const digital_earth_link =  (
        <a href='https://staging.gptl.ru/digitalearth'>
            <div className="button-link w-full flex justify-content-center align-items-center font-bold" >
                ПЕРЕЙТИ
            </div>
        </a>
    )
    const space_filming_link =  (
        <a href='https://classic.gptl.ru/'>
            <div className="button-link w-full flex justify-content-center align-items-center font-bold" >
                ПЕРЕЙТИ
            </div>
        </a>
    )
    const services_link =  (
        <a href='https://dgearth.ru/'>
            <div className="button-link w-full flex justify-content-center align-items-center font-bold" >
                ПЕРЕЙТИ
            </div>
        </a>
    )

    return (
        <div className="left-menu flex justify-content-center flex-column w-full h-full text-white">
            <div className="eco-system w-auto h-auto text-8xl flex justify-content-center align-items-center font-bold" >ЭКОСИСТЕМА ДЗЗ</div>
            { showCard && (
                <div className="card-frame">
                    <div className="card-frame-1-row">
                        <Card title="Федеральный фонд данных дистанционного зондирования Земли" subTitle=""  footer={ffd_ddz_link} className="md:w-25rem text-white h-auto  m-2">
                            <ScrollPanel style={{ width: '100%', height: '150px' }}>
                                <p className="m-0">Сервис доступа снимкам Земли с российских и иностранных спутников на территорию России и зарубежья, имеющимся в ЕТРИС ДЗЗ:</p>
                                <ul className="m-0">
                                    <li>часть снимков доступна для онлайн-просмотра в полном разрешении;</li>
                                    <li>можно заказать продукт обработки интересующего снимка;</li>
                                    <li>сервис предоставляется через геопортал, мобильное приложение и API.</li>
                                </ul>
                                <p className="m-0">Сервис предоставляет единая территориально-распределённая информационная система дистанционного зондирования Земли из космоса (ЕТРИС ДЗЗ), являющаяся основой экосистемы сервисов ДЗЗ Госкорпорации "Роскосмос". </p>
                            </ScrollPanel>
                        </Card>
                        <Card title="Цифровая Земля" subTitle=""  footer={digital_earth_link} className="md:w-25rem text-white h-auto m-2">
                            <ScrollPanel style={{ width: '100%', height: '150px' }}>
                                <p className="m-0">Сервис доступа к снимкам Земли с российских спутников на территорию России, имеющимся в ИС "Цифровая Земля":</p>
                                    <ul className="m-0">
                                        <li>все снимки доступны для онлайн-просмотра в полном разрешении;</li>
                                        <li>можно заказать продукт обработки интересующего снимка;</li>
                                        <li>бесшовные мозаики из снимков формируются периодически и доступны для онлайн-просмотра в полном разрешении;</li>
                                        <li>можно заказать бесшовную мозаику из снимков (продукт обработки множества снимков);</li>
                                        <li>сервис предоставляется через геопортал, мобильное приложение и API.</li>
                                    </ul>
                                <p className="m-0">Сервис предоставляет Информационная система (ИС) "Цифровая Земля", созданная на платформе ЕТРИС ДЗЗ.</p>
                            </ScrollPanel>
                        </Card>
                    </div>
                    <div className="card-frame-2-row">
                    <Card title="Заказ отраслевых информационных продуктов" subTitle="" footer={services_link} className="md:w-25rem text-white h-auto m-2">
                            <ScrollPanel style={{ width: '100%', height: '150px' }}>
                                <p className="m-0">Сервис заказа отраслевых информационных продуктов на основе космических снимков:</p>
                                        <ul className="m-0">
                                            <li>можно заказать базовые и мониторинговые аналитические продукты, применимые в различных отраслях;</li>
                                            <li>предоставляется через геопортал и API.</li>
                                        </ul>
                                <p className="m-0">Сервис предоставляет программный Комплекс отраслевых информационных сервисов, функционирующий в ИТ-инфраструктуре ИС "Цифровая Земля".</p>
                            </ScrollPanel>
                        </Card>
                        <Card title="Заказ проведения космической съёмки" subTitle=""  footer={space_filming_link} className="md:w-25rem text-white h-auto m-2">
                            <ScrollPanel style={{ width: '100%', height: '150px' }}>
                                <p className="m-0">Сервис заказа проведения космической съёмки Земли российскими спутниками:</p>
                                    <ul className="m-0">
                                        <li>можно заказать проведение космической съёмки Земли и формирование продуктов обработки полученных данных;</li>
                                        <li>предоставляется через геопортал и API.</li>
                                    </ul>
                                <p className="m-0">Сервис предоставляет ЕТРИС ДЗЗ.</p>
                            </ScrollPanel>
                        </Card>
                       
                    </div>
                </div>
            )}
        </div>
    )
}
        

export default LeftMenu 
import React, {useRef, useEffect} from "react"

import * as Three from "three"
import { useLoader } from "@react-three/fiber";

import Earth from '../../textures/earth_atmos_2048.jpg'
import Specular from '../../textures/earth_specular_2048.jpg'
import Normal from '../../textures/earth_normal_2048.jpg'

import { TextureLoader } from "three/src/loaders/TextureLoader";

import { useFrame } from "@react-three/fiber";

const Sphere = () => {
   console.log(Earth, Specular, Normal)

   const meshRef = useRef<any>(null);

   const textureEarth = useLoader(TextureLoader, Earth);
   const textureSpecular = useLoader(TextureLoader, Specular);
   const textureNormal = useLoader(TextureLoader, Normal);

    useEffect(() => console.log(meshRef.current))
    useFrame(() => (meshRef.current.rotation.y += 0.001));

    return (
        <mesh ref={meshRef} position={[0, 0, -2]} rotation={[0.5, 0, 0]}>
            <sphereGeometry args={[2, 64, 32]} />
            <meshPhongMaterial
                specular={0x7c7c7c}
                shininess={15}
                map={textureEarth}
                specularMap={textureSpecular}
                normalMap={textureNormal}
              />
        </mesh>
    )
}

export default Sphere